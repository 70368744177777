<script>
import MainContainer from '@/components/main/MainContainer.vue'

export default {
	name: 'QaBlock',
	components: {
		MainContainer,
	},
}
</script>

<template>
	<section class="pb-[20px] lg:py-[40px]">
		<MainContainer
			class="relative flex flex-col items-stretch gap-x-[55px] gap-y-[10px] md:flex-row"
		>
			<div
				class="relative bg-[#F2D04C] rounded-[45px] py-[50px] px-[44px] pb-[305px] w-full md:py-[60px] md:px-[56px] lg:py-[85px] lg:px-[77px] md:max-w-[639px]"
			>
				<img
					src="@/assets/img/main/fisher-real.png"
					alt=""
					class="max-w-[280px] right-[50%] transform translate-x-1/2 absolute bottom-0 md:right-[-8%] md:translate-x-0 md:w-[59%]"
				/>
				<div class="relative z-1 md:max-w-[292px]">
					<div class="font-main font-extrabold text-[24px] text-dark leading-7 mb-[20px]">
						{{ $t('titles.start_using') }}
					</div>
					<div class="font-main font-normal text-[16px] text-dark leading-6 mb-[30px]">
						{{ $t('titles.start_using') }}
					</div>

					<router-link
						:to="`/${$i18n.locale}/registration`"
						class="w-full max-w-[289px] text-center inline-block border border-[2px] border-dark rounded-[45px] px-[20px] py-[30px] cursor-pointer bg-transparent font-semibold text-[16px] duration-300 hover:no-underline hover:!bg-dark hover:!text-[#FFF] md:w-auto"
					>
						{{ $t('commands.sign_in') }}
					</router-link>
				</div>
			</div>

			<div class="w-full flex flex-col gap-[17px] md:max-w-[424px]">
				<div
					class="relative overflow-hidden rounded-[45px] bg-white flex flex-col items-start justify-center pl-[74px] pr-[35px] h-[191px]"
				>
					<div
						class="absolute top-[-32px] right-[-24px] w-[93px] h-[93px] rounded-full bg-[#157fee]"
					></div>
					<router-link
						:to="`/${$i18n.locale}/faq`"
						class="block absolute z-1 top-[18px] right-[22px] cursor-pointer"
					>
						<img src="@/assets/svgs/link-arrow-circle-right.svg" alt="" class="block" />
					</router-link>
					<div
						class="inline-flex items-center gap-[10px] mb-[5px] font-main font-extrabold text-[24px] leading-6 text-second"
					>
						<img src="@/assets/svgs/message-question.svg" alt="" />
						<span>{{ $t('titles.faq') }}</span>
					</div>
					<div class="font-main font-normal text-[16px] text-second">
						{{ $t('titles.faq_list') }}
					</div>
				</div>

				<div
					class="relative overflow-hidden rounded-[45px] bg-white flex flex-col items-start justify-center pl-[74px] pr-[35px] h-[191px]"
				>
					<div
						class="absolute top-[-32px] right-[-24px] w-[93px] h-[93px] rounded-full bg-[#157fee]"
					></div>
					<router-link :to="'/'" class="block absolute z-1 top-[18px] right-[22px] cursor-pointer">
						<img src="@/assets/svgs/link-arrow-circle-right.svg" alt="" class="block" />
					</router-link>
					<div
						class="inline-flex items-center gap-[10px] mb-[5px] font-main font-extrabold text-[24px] leading-6 text-second"
					>
						<img src="@/assets/svgs/video-play.svg" alt="" />
						<span>{{ $t('titles.video_inst') }}</span>
					</div>
					<div class="font-main font-normal text-[16px] text-second">
						{{ $t('titles.how_work_video_inst') }}
					</div>
				</div>
			</div>
		</MainContainer>
	</section>
</template>

<style scoped>
.text-decoration-thickness {
	text-decoration-thickness: 1px;
}
</style>
