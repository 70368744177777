<script>
import MainContainer from '@/components/main/MainContainer.vue'
import UiHead from '@/components/UI/atoms/UiHead.vue'

export default {
	name: 'RaitingTop',
	components: {
		MainContainer,
		UiHead,
	},
	props: {
		companyTop: Array,
		regionTop: Array,
		reservoirTop: Array,
	},
}
</script>

<template>
	<section
		v-show="reservoirTop.length || regionTop.length || companyTop.length"
		class="pb-[20px] lg:py-[40px]"
	>
		<MainContainer class="relative">
			<div class="mb-[40px] md:mb-[60px]">
				<UiHead>
					{{ $t('titles.top_month') }}
				</UiHead>
			</div>
			<div class="grid grid-cols-1 gap-x-[29px] gap-y-[10px] md:grid-cols-3">
				<div v-show="reservoirTop.length" class="bg-white rounded-[45px] p-[35px]">
					<div class="mb-[25px]">
						<div class="font-main font-medium text-[16px] text-second mb-[5px]">
							{{ $t('titles.top_ponds') }}
						</div>
						<div class="font-main font-light text-[14px] text-[#8099BF]">
							{{ $t('titles.by_trade') }}
						</div>
					</div>

					<div class="w-full flex flex-col gap-[10px]">
						<div
							v-for="item in reservoirTop"
							:key="item.id"
							class="flex items-center justify-between gap-3"
						>
							<div class="flex items-center gap-[20px]">
								<img src="@/assets/svgs/top-item-1.svg" alt="" class="block" />
								<span class="font-main font-normal text-[13px] text-second">{{ item.title }}</span>
							</div>
							<div class="font-main font-bold text-[14px] text-[#157fee] whitespace-nowrap">
								{{ `${item.percentage} %` }}
							</div>
						</div>
					</div>
				</div>

				<div v-show="regionTop.length" class="bg-white rounded-[45px] p-[35px]">
					<div class="mb-[25px]">
						<div class="font-main font-medium text-[16px] text-second mb-[5px]">
							{{ $t('titles.top_region') }}
						</div>
						<div class="font-main font-light text-[14px] text-[#8099BF]">
							{{ $t('titles.by_trade') }}
						</div>
					</div>

					<div class="w-full flex flex-col gap-[10px]">
						<div
							v-for="item in regionTop"
							:key="item.id"
							class="flex items-center justify-between gap-3"
						>
							<div class="flex items-center gap-[20px]">
								<img src="@/assets/svgs/top-item-2.svg" alt="" class="block" />
								<span class="font-main font-normal text-[13px] text-second">{{ item.title }}</span>
							</div>
							<div class="font-main font-bold text-[14px] text-[#B59F8A] whitespace-nowrap">
								{{ `${item.percentage} %` }}
							</div>
						</div>
					</div>
				</div>

				<div v-show="companyTop.length" class="bg-white rounded-[45px] p-[35px]">
					<div class="mb-[25px]">
						<div class="font-main font-medium text-[16px] text-second mb-[5px]">
							{{ $t('titles.top_subject') }}
						</div>
						<div class="font-main font-light text-[14px] text-[#8099BF]">
							{{ $t('titles.fisheries') }}
						</div>
					</div>

					<div class="w-full flex flex-col gap-[10px]">
						<div
							v-for="item in companyTop"
							:key="item.id"
							class="flex items-center justify-between gap-3"
						>
							<div class="flex items-center gap-[20px]">
								<img src="@/assets/svgs/top-item-3.svg" alt="" class="block" />
								<span class="font-main font-normal text-[13px] text-second">{{ item.title }}</span>
							</div>
							<div class="font-main font-bold text-[14px] text-[#88DB8B] whitespace-nowrap">
								{{ `${item.percentage} %` }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainContainer>
	</section>
</template>

<style scoped></style>
