<script>
import { api, urlApi } from '@/boot/axios'

import MainContainer from '@/components/main/MainContainer.vue'
import UiHead from '@/components/UI/atoms/UiHead.vue'

const SUFFIXES = {
	kz: '_kz',
	ru: '',
}

export default {
	name: 'MainNews',
	components: {
		MainContainer,
		UiHead,
	},
	data() {
		return {
			paginationIndex: 0,
			news: [],
			urlApi: urlApi,
		}
	},
	computed: {
		getSuffix() {
			return SUFFIXES[this.$i18n.locale]
		},
	},
	mounted() {
		this.getNews()
	},
	methods: {
		changePagination(index) {
			this.paginationIndex = index

			if (index == 1) {
				document.getElementsByClassName('news__sliders--block')[0].scrollLeft =
					document.getElementsByClassName('news__sliders--block')[0].scrollLeft + 800
			} else {
				document.getElementsByClassName('news__sliders--block')[0].scrollLeft =
					document.getElementsByClassName('news__sliders--block')[0].scrollLeft - 800
			}
		},
		getNews() {
			api.get('guest/news', {}).then((response) => {
				this.news = response.data.data?.slice(0, 4)
			})
		},
	},
}
</script>

<template>
	<section class="pb-[20px] lg:py-[40px]">
		<MainContainer class="relative">
			<div class="mb-[40px] md:mb-[60px]">
				<UiHead class="mb-[10px]">
					{{ $t('titles.news') }}
				</UiHead>
				<p class="font-main font-normal text-[16px] text-second">{{ $t('titles.newText') }}</p>
			</div>
			<div
				v-if="news.length > 0"
				id="news"
				class="grid grid-cols-1 gap-[15px] gap-y-[30px] mb-[60px] md:grid-cols-2 md:gap-[15px] lg:grid-cols-4 lg:gap-[30px]"
			>
				<router-link
					v-for="(item, index) in news"
					:key="index"
					:to="`/${$i18n.locale}/description/${item.id}`"
					class="overflow-hidden flex flex-col items-stretch hover:no-underline"
				>
					<div
						v-if="item.images.length > 0"
						class="w-full h-[264px] max-h-[264px] mb-[30px] overflow-hidden"
					>
						<img
							:src="urlApi + item.images[0].path"
							:alt="item['title' + getSuffix]"
							class="w-full h-full object-contain"
						/>
					</div>
					<div class="grow flex flex-col overflow-hidden">
						<div
							class="grow overflow-hidden font-main font-semibold text-[16px] leading-6 text-second no-underline mb-[20px] hover:no-underline"
						>
							{{ item['title' + getSuffix] }}
						</div>
						<div class="font-main font-bold text-[11px] leading-5 text-main">
							{{ item.created_at | formatOnlyDate }}
						</div>
					</div>
				</router-link>
			</div>
			<div class="flex justify-center">
				<router-link
					:to="`/${$i18n.locale}/news`"
					class="w-full max-w-[381px] text-center m-auto inline-block border-[2px] border-main rounded-[45px] font-semibold text-[16px] text-main py-[15px] px-[20px] hover:no-underline md:w-auto"
				>
					{{ $t('labels.all_news') }}
				</router-link>
			</div>
		</MainContainer>
	</section>
</template>

<style scoped></style>
