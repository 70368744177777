<script>
import MainContainer from '@/components/main/MainContainer.vue'
import UiHead from '@/components/UI/atoms/UiHead.vue'

export default {
	name: 'MainFunction',
	components: {
		MainContainer,
		UiHead,
	},
	data() {
		return {
			left: [
				{
					title: 'main_functions.1.title',
					text: 'main_functions.1.text',
					classes: `max-w-full md:max-w-[397px]`,
				},
				{
					title: 'main_functions.2.title',
					text: 'main_functions.2.text',
					classes: `max-w-full md:max-w-[322px]`,
				},
				{
					title: 'main_functions.3.title',
					text: 'main_functions.3.text',
					classes: `max-w-full md:max-w-[382px]`,
				},
			],
			right: [
				{
					title: 'main_functions.4.title',
					text: 'main_functions.4.text',
					classes: `max-w-full md:max-w-[313px]`,
				},
				{
					title: 'main_functions.5.title',
					text: 'main_functions.5.text',
					classes: `max-w-full md:max-w-[313px]`,
				},
				{
					title: 'main_functions.6.title',
					text: 'main_functions.6.text',
					classes: `max-w-full md:max-w-[357px]`,
				},
			],
		}
	},
}
</script>

<template>
	<section class="pt-[10px] pb-[40px] md:pt-[15px] md:pb-[84px] lg:pt-[25.19px] lg:pb-[84px]">
		<MainContainer class="relative">
			<div class="mb-[40px] md:mb-[60px] lg:mb-[95px]">
				<UiHead>
					{{ $t('titles.main_functions') }}
				</UiHead>
			</div>

			<div class="relative flex flex-col justify-between items-stretch md:flex-row">
				<div class="relative z-[2] flex flex-col items-start gap-[30px] text-left md:gap-[46px]">
					<div v-for="item in left" :key="item.title" :class="item.classes">
						<UiHead :tag="'h5'" class="mb-[10px]">
							<span v-html="$t(item.title)"></span>
						</UiHead>
						<div class="font-main font-light text-[14px] text-second">
							{{ $t(item.text) }}
						</div>
					</div>
				</div>
				<div
					class="w-[80.37%] top-1/2 left-1/2 min-h-[440px] mx-auto transform z-0 md:absolute md:-translate-x-1/2 md:-translate-y-1/2 flex items-center justify-center"
				>
					<img
						src="@/assets/img/main/main_functions.png"
						alt=""
						class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-[663px] min-w-[860px]"
					/>
				</div>
				<div
					class="relative z-[2] flex flex-col items-end gap-[30px] text-left md:!text-right md:gap-[46px]"
				>
					<div v-for="item in right" :key="item.title" :class="item.classes">
						<UiHead :tag="'h5'" class="mb-[10px]">
							<span v-html="$t(item.title)"></span>
						</UiHead>
						<div class="font-main font-light text-[14px] text-second">
							{{ $t(item.text) }}
						</div>
					</div>
				</div>
			</div>
		</MainContainer>
	</section>
</template>

<style>
.main-logo span strong {
	font-weight: 800 !important;
	font-size: 12px;
	line-height: 0.9;
	text-transform: uppercase;
}
</style>
