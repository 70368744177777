<template>
	<div class="wrap__content">
		<div
			class="absolute top-[107%] left-1/2 transform -translate-x-1/2 w-[1914px] h-[1914px] rounded-full bg-gradient-to-b from-white to-[#F5FCFF]"
		></div>

		<div class="absolute top-[378%] w-full h-[1100px] overflow-hidden">
			<div
				class="absolute top-0 left-1/2 transform -translate-x-1/2 w-[1914px] h-[1914px] rounded-full bg-gradient-to-b from-[#F5FCFF] to-white"
			></div>
		</div>
		<MainBanner />
		<MainFunction />
		<MainVideoBanner />
		<QaBlock />
		<TopList />
		<MainNews />
	</div>
</template>

<script>
import MainBanner from '../components/MainBanner.vue'
import MainFunction from '../components/MainFunction.vue'
import MainVideoBanner from '../components/MainVideoBanner.vue'
import QaBlock from '../components/QaBlock.vue'
import TopList from '../components/TopList.vue'
import MainNews from '../components/MainNews.vue'

export default {
	components: {
		MainBanner,
		MainFunction,
		MainVideoBanner,
		QaBlock,
		TopList,
		MainNews,
	},
	head: {
		title() {
			return {
				inner: this.$t('site.home.title'),
			}
		},
		meta: function () {
			return [{ name: 'description', content: this.$t('site.recovery.description') }]
		},
	},
}
</script>

<style></style>
