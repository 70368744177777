<script>
import MainContainer from '@/components/main/MainContainer.vue'

export default {
	name: 'MainBanner',
	components: {
		MainContainer,
	},
	computed: {
		getRuleLink() {
			if (this.$i18n.locale === 'kz') return `https://adilet.zan.kz/kaz/docs/V1600014227/info`
			return `https://adilet.zan.kz/rus/docs/V1600014227/info`
		},
	},
}
</script>

<template>
	<section class="py-[10px] lg:py-[20px]">
		<MainContainer class="relative">
			<img
				src="@/assets/img/main/wave.svg"
				alt=""
				class="absolute top-[31%] right-[6%] z-0 lg:right-0 lg:top-0"
			/>
			<div class="relative z-[10] pb-[229px] md:pb-0">
				<h1
					class="font-main font-black text-[36px] text-second leading-[1.25] max-w-[497px] mb-[10px] break-all md:text-[40px] lg:text-[48px]"
				>
					{{ $t('site.home.menuTitle') }}
				</h1>
				<h4
					class="font-main font-normal text-[20px] text-second leading-[1.25] max-w-[449px] mb-[10px] lg:text-[23px]"
				>
					{{ $t('site.home.menuSubtitle') }}
				</h4>
				<a
					:href="getRuleLink"
					target="_blank"
					class="text-decoration-thickness block font-main font-normal text-[16px] text-externalLink1 leading-[1.25] underline max-w-[449px] hover:text-externalLink1 mb-[36px]"
					>{{ $t('site.home.menuLink') }}</a
				>
			</div>

			<div class="absolute top-[22%] right-0 z-0 w-[194px] md:w-[300px] md:top-0 lg:w-[375px]">
				<img src="@/assets/img/main/fisher.svg" alt="" class="w-full" />
				<router-link
					:to="`/`"
					class="absolute top-[20%] right-[99%] z-0 w-[173px] md:top-[4%] md:right-[62%]"
				>
					<img src="@/assets/img/main/popup.svg" alt="" class="hidden md:block" />
					<img src="@/assets/img/main/popup-left.svg" alt="" class="block md:hidden" />
					<span
						class="font-main font-normal text-[16px] text-white absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-center p-2 !pr-[15px] md:mb-[20px] md:!pr-2"
						>{{ $t('labels.need_help') }}</span
					>
				</router-link>
			</div>

			<div
				class="relative z-5 grid grid-cols-1 gap-[5px] w-full max-w-[679px] mb-[46.81px] md:grid-cols-3"
			>
				<router-link
					:to="`/${$i18n.locale}/account/trade/`"
					class="group flex flex-col items-center text-center gap-[10px] p-[15px] rounded-[5px] bg-bgOverlayLight duration-300 font-main font-medium text-[14px] leading-[1.25] text-second h-[111px] cursor-pointer hover:bg-main hover:text-white hover:no-underline md:items-start md:!text-left"
				>
					<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current stroke-2 text-second group-hover:text-white duration-300'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.941 26.25v-7.292M17.5 22.604h-7.292M11.666 2.917v4.375M23.333 2.917v4.375M4.287 14.335c-.145-6.854 2.26-8.75 7.103-9.114l11.666-.234c4.871.175 7.336 1.97 7.482 8.823l.19 8.998c.116 6.009-1.27 9.027-8.561 9.188l-8.75.175c-7.292.146-8.809-2.83-8.925-8.823l-.059-3.092"/></svg>
					<span>{{ $t('commands.add_record_trade') }}</span>
				</router-link>
				<router-link
					:to="`/${$i18n.locale}/account/reporting/`"
					class="group flex flex-col items-center text-center gap-[10px] p-[15px] rounded-[5px] bg-bgOverlayLight duration-300 font-main font-medium text-[14px] leading-[1.25] text-second h-[111px] cursor-pointer hover:bg-main hover:text-white hover:no-underline md:items-start md:!text-left"
				>
					<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current stroke-2 text-second group-hover:text-white duration-300'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.094 21.875h3.864l-2.916 2.917M10.209 21.875h1.458M18.958 21.875l-2.916-2.917M2.917 13.125c0-7.292 2.916-10.208 10.208-10.208h7.291"/><path d="M32.083 14.583v7.292c0 7.292-2.916 10.208-10.208 10.208h-8.75c-7.292 0-10.209-2.916-10.209-10.208v-2.946M26.25 14.583c-4.375 0-5.834-1.458-5.834-5.833V2.917l11.667 11.666"/></svg>
					<span>{{ $t('commands.send_report') }}</span>
				</router-link>
				<router-link
					:to="`/${$i18n.locale}/account/`"
					class="group flex flex-col items-center text-center gap-[10px] p-[15px] rounded-[5px] bg-bgOverlayLight duration-300 font-main font-medium text-[14px] leading-[1.25] text-second h-[111px] cursor-pointer hover:bg-main hover:text-white hover:no-underline md:items-start md:!text-left"
				>
					<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stroke-current stroke-2 text-second group-hover:text-white duration-300'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.788 11.725c.32.642.51 1.37.51 2.144-.014 2.581-2.042 4.696-4.608 4.768a1.403 1.403 0 00-.35 0 4.767 4.767 0 01-4.609-4.768c0-2.64 2.13-4.784 4.784-4.784M27.33 28.262a14.487 14.487 0 01-9.83 3.821 14.487 14.487 0 01-9.83-3.82c.147-1.371 1.022-2.713 2.582-3.763 3.996-2.654 10.53-2.654 14.496 0 1.56 1.05 2.435 2.392 2.581 3.762z"/><path d="M5.833 8.75a14.57 14.57 0 00-2.917 8.75c0 8.05 6.534 14.583 14.584 14.583S32.083 25.55 32.083 17.5 25.55 2.917 17.5 2.917c-2.086 0-4.084.437-5.877 1.24"/></svg>
					<span class="max-w-[160px]">{{ $t('commands.enter_cabinet') }}</span>
				</router-link>
			</div>
		</MainContainer>
	</section>
</template>

<style scoped>
.text-decoration-thickness {
	text-decoration-thickness: 1px;
}
</style>
