<script>
import { apiSecond } from '@/boot/axios'

import RaitingTop from './RaitingTop.vue'
import FishListPercentage from './FishListPercentage.vue'

export default {
	name: 'TopList',
	components: {
		RaitingTop,
		FishListPercentage,
	},
	data() {
		return {
			company_top: [],
			fish_top: [],
			region_top: [],
			reservoir_top: [],
		}
	},
	async mounted() {
		await this.getTop()
	},
	methods: {
		async getTop() {
			try {
				const response = await apiSecond.get(`v1/public-dashboard/top-trade`)

				if (response?.data?.data) {
					this.company_top = response?.data?.data.company_top
					this.fish_top = response?.data?.data.fish_top
					this.region_top = response?.data?.data.region_top
					this.reservoir_top = response?.data?.data.reservoir_top
				}
			} catch (error) {
				console.log(error)
			}
		},
	},
}
</script>

<template>
	<div>
		<RaitingTop
			:company-top="company_top"
			:region-top="region_top"
			:reservoir-top="reservoir_top"
		/>
		<FishListPercentage :fish-top="fish_top" />
	</div>
</template>

<style scoped></style>
