<script>
import MainContainer from '@/components/main/MainContainer.vue'
import UiHead from '@/components/UI/atoms/UiHead.vue'

export default {
	name: 'FishListPercentage',
	components: {
		MainContainer,
		UiHead,
	},
	props: {
		fishTop: Array,
	},
	computed: {
		getBottom() {
			return (percent) => {
				return Math.floor((Math.random() * (100 - percent)) / 2)
			}
		},
	},
}
</script>

<template>
	<section v-show="fishTop.length" class="pb-[20px] lg:py-[40px]">
		<MainContainer class="relative">
			<div class="mb-[40px] md:mb-[60px]">
				<UiHead>
					{{ $t('titles.FishListPercentage') }}
				</UiHead>
			</div>
			<div
				class="w-full relative pt-[26px] pr-[39px] pb-[15.08px] pl-[20px] overflow-hidden md:pl-[40px] lg:pl-[60px] pb-[85px]"
			>
				<img
					src="@/assets/img/main/fish-ocean.svg"
					alt=""
					class="absolute bottom-0 left-1/2 transform -translate-x-1/2 min-w-[1200px]"
				/>
				<div class="relative z-1 flex justify-between items-end gap-2 h-[346.92px] min-w-[600px]">
					<div
						v-for="fish in fishTop"
						:key="fish.id"
						:style="{
							marginBottom: `${getBottom(100 - fish.percentage)}px`,
							height: `${10 + fish.percentage}%`,
						}"
						class="flex flex-col items-center gap-2"
					>
						<span
							class="font-main font-extrabold text-[26px] text-green leading-5 whitespace-nowrap"
							>{{ `${fish.percentage} %` }}</span
						>
						<span
							class="font-main font-light text-[14px] text-second py-1 px-2 bg-[#E6F3FE] rounded-[15px]"
							>{{ fish.title }}</span
						>
						<div class="grow border-l border-dashed border-l-main"></div>
						<img src="@/assets/svgs/fish.svg" alt="" class="block" />
					</div>
				</div>
			</div>
		</MainContainer>
	</section>
</template>

<style scoped></style>
