<script>
import MainContainer from '@/components/main/MainContainer.vue'

export default {
	name: 'MainVideoBanner',
	components: {
		MainContainer,
	},
	data() {
		return {
			isActive: false,
		}
	},
}
</script>
<template>
	<section class="pb-[20px] lg:pb-[40px]">
		<MainContainer>
			<div class="relative">
				<img
					src="@/assets/img/main/main_video_banner.png"
					alt=""
					class="block w-full cursor-pointer"
					@click="isActive = true"
				/>

				<div
					v-show="isActive"
					class="fixed z-[1100] top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-[#000000ec]"
					@click.stop="isActive = false"
				>
					<div class="relative box-border w-[90%] max-w-[880px] box-border" @click.stop="">
						<div class="w-full box-border aspect-w-8 aspect-h-5 rounded-[45px] overflow-hidden">
							<iframe
								src="https://www.youtube.com/embed/0-j-s7gjTFI?si=SYN5XzZLkqq6B8Nl"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								referrerpolicy="strict-origin-when-cross-origin"
								allowfullscreen
							></iframe>
						</div>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'absolute top-[-45px] right-1/2 transform -translate-x-1/2 block w-[24px] h-[24px] z-[100] cursor-pointer'" @click="isActive = false" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 21L21 3M21 21L3 3" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
					</div>
				</div>
			</div>
		</MainContainer>
	</section>
</template>
